import React from 'react';
import LogsTable from '../../components/LogsTable';

const Token = () => (
  <>
    <LogsTable />
  </>
);

export default Token;
